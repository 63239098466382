define("discourse/plugins/discourse-narrative-bot/initializers/new-user-narrative", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api"], function (_exports, _ajax, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initialize(api) {
    const messageBus = api.container.lookup("message-bus:main");
    const currentUser = api.getCurrentUser();
    const appEvents = api.container.lookup("service:app-events");
    api.modifyClass("component:site-header", {
      didInsertElement() {
        this._super(...arguments);
        this.dispatch("header:search-context-trigger", "header");
      }
    });
    api.modifyClass("controller:topic", {
      _togglePostBookmark(post) {
        // if we are talking to discobot then any bookmarks should just
        // be created without reminder options, to streamline the new user
        // narrative.
        const discobotUserId = -2;
        if (post.user_id === discobotUserId && !post.bookmarked) {
          return (0, _ajax.ajax)("/bookmarks", {
            type: "POST",
            data: {
              post_id: post.id
            }
          }).then(response => {
            post.setProperties({
              "topic.bookmarked": true,
              bookmarked: true,
              bookmark_id: response.id
            });
            post.appEvents.trigger("post-stream:refresh", {
              id: this.id
            });
          });
        }
        return this._super(post);
      }
    });
    api.attachWidgetAction("header", "headerSearchContextTrigger", function () {
      if (this.site.mobileView) {
        this.state.skipSearchContext = false;
      } else {
        this.state.contextEnabled = true;
        this.state.searchContextType = "topic";
      }
    });
    if (messageBus && currentUser) {
      messageBus.subscribe(`/new_user_narrative/tutorial_search`, () => {
        appEvents.trigger("header:search-context-trigger");
      });
    }
  }
  var _default = _exports.default = {
    name: "new-user-narratve",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.discourse_narrative_bot_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.7", initialize);
      }
    }
  };
});